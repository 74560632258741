<script setup>
    import BlogSlider from './partials/BlogSlider.vue'
    import LatestArticles from './partials/LatestArticles.vue'
   
    import axios from 'axios'
    import { ref, onMounted } from 'vue'
    
    // variables
    const posts = ref([])
    const categoriesList = ref([
        { 'title': 'All', 'description': 'Showing posts with all categories' },
        { 'title': 'Short Stories', 'description': 'Showing short stories' },
        { 'title': 'Essays', 'description': 'Showing essays' },
    ])

    // const signup = async () => {
    //     try {
    //         const response = await axios.post('https://api.onlekh.com/api/signup', {
    //             'email' : 'thetestuser@gmail.com',
    //             'name' : 'The Test User',
    //             'username' : 'the_testuser',
    //             'password' : 'hello12345!@#',
    //             'password_confirmation' : 'hello12345!@#',
    //         })

    //         console.log(response)
    //     } catch (error) {
    //         console.error(error)
    //     }
    // }

    // const login = async () => {
    //     try {
    //         const response = await axios.post('http://gdpl.gaminguptodate.com/api/login', {
    //             email: 'iambasanta@gmail.com',
    //             password: 'iambasanta1@',
    //         });

    //         console.log(response)
    //     } catch (error) {
    //         console.error('Error:', error)
    //     }
    // }

    // const csrf = async () => {
    //     try {
    //         const response = await axios.get('https://gdpl.gaminguptodate.com/api/gettoken')
    //         console.log(response)
    //     } catch (error) {
    //         console.error('Error:', error)
    //     }
    // }

    // methods
    const fetchData = async () => {
        try {
            const response = await axios.get('https://api.yatharthamag.com/api/blogs')
            posts.value = response.data.posts.data
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

    const blogSliders = ref([])

    onMounted(async () => {
        await fetchData()
        blogSliders.value = posts.value
    })

    const customSearch = async (search) => {
        try {
            const response = await axios.get('https://api.yatharthamag.com/api/blogs?search=' + search)
            posts.value = response.data.posts.data
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }
</script>
<script>
    export default {
        name : 'AppHome'
    }
</script>

<template>
    <div class="space-y-16">
        <blog-slider :posts="blogSliders"></blog-slider>
        <latest-articles :posts="posts" :categoriesList="categoriesList" :customSearch="customSearch"></latest-articles>
    </div>
</template>

<style scoped>
</style>