<script setup>

    import { defineProps } from 'vue';

    defineProps({
        author : Object,
    })

</script>

<script>

    export default {
        name : 'PostAuthor',
    }

</script>

<template>

    <section class="w-full rounded-lg py-8 bg-slate-600 text-slate-50" id="post-author">

        <div class="flex flex-col items-center space-y-2">
            <div class="rounded-circle m-auto">
                <img class="rounded-full object-cover size-28" src="/images.jpeg" alt="">
            </div>
            <div class="font-bold text-xl mb-2" id="author-name">
                {{ author.name }}
            </div>
        </div>
        <div class="container
                    md:w-4/5 w-10/12
                    mx-auto md:mt-0 mt-4
                    md:space-x-8 space-x-0">

            <div class="flex flex-col justify-start items-start
                        w-auto
                        mt-4">
                <div v-show="author.bio" v-html="author.bio" id="author-description">
                    
                </div>

                <div v-show="!author.bio" id="author-description">
                    A passionate writer emphasizing vivid imagery in his writings, who uses letters to speak for the suppressed ones. A passionate writer emphasizing vivid imagery in his writings, who uses letters to speak for the suppressed ones. A passionate writer emphasizing vivid imagery in his writings, who uses letters to speak for the suppressed ones. A passionate writer emphasizing vivid imagery in his writings, who uses letters to speak for the suppressed ones.
                </div>
            </div>

        </div>
    </section>
   
</template>

<style scoped>
</style>