<script setup>

    import { ref } from 'vue'

    const menuList = ref({
        'home' : {
            'title' : 'Home',
            'url' : '/',
        },
        'about' : {
            'title' : 'About Us',
            'url' : '/',
        },
        'contact' : {
            'title' : 'Contact Us',
            'url' : '/',
        }
    })

    const colors = ref({
        'bg' : 'dark:bg-slate-700 dark:text-slate-50 bg-slate-50 text-slate-700',
        'nobg' : 'dark:text-slate-700 text-slate-50',
    })

</script>

<script>
    export default {
        name : 'SiteFooter',
    }
</script>

<template>

    <section
        v-bind:class="colors.bg"
        class="
            mt-auto
            rounded-t-lg
            h-14 w-100
            bottom-0
        "
        id="site-header"
    >
        <div class="container md:w-3/4 w-full m-auto flex flex-row justify-between items-center h-full">

            <div id="site-title">
                <img src="favicon.ico" alt="">
            </div>

            <div id="header-menu">
                <ul class="flex space-x-8">
                    <li v-for="(item, index) in menuList" v-bind:key="index">
                        {{ item.title }}
                    </li>
                </ul>
            </div>

            <div id="site-action">
                <button>
                    Subscribe
                </button>
            </div>
        </div>
    </section>

</template>

<style scoped>
</style>