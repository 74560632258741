<template>

  <div class="h-100 flex flex-col justify-between flex-wrap">
    <site-header></site-header>
    <main class="md:w-2/3 md:mx-auto mx-4 my-5">
      <RouterView />
    </main>
    <site-footer></site-footer>
  </div>

  
</template>

<script setup>
  // Site Essentials
  import SiteHeader from './components/partials/SiteHeader.vue';
  import SiteFooter from './components/partials/SiteFooter.vue';

  // Site Headers
  // import BlogSlider from './components/partials/BlogSlider.vue';
  // import LatestArticles from './components/partials/LatestArticles.vue';
import { RouterView } from 'vue-router';

</script>

<script>

export default {
  name: 'App',
}
</script>

<style>
</style>
