<script setup>

    import { ref } from 'vue'
    import ButtonPrimary from '../buttons/ButtonPrimary.vue'

    const menuList = ref({
        'home' : {
            'title' : 'Home',
            'url' : '/',
            'icon' : 'fa-solid fa-house',
            'active' : true
        },
        'about' : {
            'title' : 'About Us',
            'url' : '/',
            'icon' :  'fa-solid fa-circle-info',
            'active' : false,
        },
        'contact' : {
            'title' : 'Contact Us',
            'url' : '/',
            'icon' : 'fa-solid fa-circle-question',
            'active' : false,
        }
    })

    const colors = ref({
        'bg' : 'dark:bg-slate-700 dark:text-slate-50 bg-slate-50 text-slate-700',
        'nobg' : 'dark:text-slate-700 text-slate-50',
    })

</script>

<script>
    export default {
        name : 'SiteHeader',
    }
</script>

<template>

    <section
        v-bind:class="colors.bg"
        class="
            shadow-lg
            border-b-1
            md:rounded-b-lg rounded-b-md
            md:h-20 h-16 w-100
            sticky top-0
        "
        id="site-header"
    >
        <div class="md:w-3/4 w-100 md:m-auto mx-4 flex flex-row justify-between items-center h-full">

            
            <div id="site-title">
                <img src="favicon.ico" alt="">
            </div>

            <div class="md:block hidden" id="header-menu">
                <ul class="flex space-x-8">
                    <li v-for="(item, index) in menuList" :class="{ 'text-rose-400': item.active }" v-bind:key="index">
                        <RouterLink :to="{ path: '/' }">
                            <i :class="item.icon"></i> {{ item.title }}
                        </RouterLink>
                    </li>
                </ul>
            </div>

            <div id="site-action">
                <button-primary>
                    Subscribe
                </button-primary>
            </div>
        </div>
    </section>

</template>

<style scoped>

    section#site-header {
        z-index: 1000
    }

</style>