<script setup>

    import { ref, defineProps } from 'vue';

    defineProps({
        
    })

    const colors = ref({
        'bg' : 'bg-slate-700 text-slate-50 dark:bg-slate-50 dark:text-slate-700',
        'nobg' : 'text-slate-700 dark:text-slate-50',
    })
</script>
<script>
    export default {
        name : 'ButtonPrimary'
    }
</script>
<template>
    <button 
        :class="colors.bg" 
        class="
            rounded-lg
            py-2 px-3
            font-bold
            border-2
            hover:bg-transparent hover:text-slate-50 hover:border-slate-50
        ">
        <slot></slot>
    </button>
</template>
<style scoped>
</style>