<script setup>

    // import InputSearch from '../forms/InputSearch.vue'
    import ListPost from '../posts/ListPost.vue'
    import { ref, defineProps } from 'vue'

    const props = defineProps({
        posts: Object,
        categoriesList: Object,
        customSearch: Function,
    })

    const search = ref('')

    const handleCustomSearch = () => {
        props.customSearch(search.value)
    }

    // const filterByCategory = computed((category) => {

    // })
</script>

<script>
    export default {
        name : 'BlogSlider',
    }
</script>

<template>

    <section
            class="
                flex flex-col space-y-8
                rounded-lg
            "
    >
        <div class="flex flex-row flex-wrap gap-y-4 justify-between items-center">
            <h2 class="font-extrabold text-2xl" id="latest-articles">
                Latest Articles
            </h2>

            <div id="post-categories">
                <ul class="flex flex-row justify-between items-center space-x-4">
                    <li v-for="(item, index) in categoriesList" :key="index">
                        <span class="font-bold text-slate-600">
                            {{ item.title }}
                        </span>
                    </li>
                </ul>
            </div>

            <div id="post-search">
                <form @submit.prevent="handleCustomSearch" method="post">
                    <!-- <input-search v-model="search" placeholder="Search"></input-search> -->
                     <input class="border rounded-lg p-2 w-full focus:outline-none focus:shadow-sm" placeholder="Search" type="search" v-model="search" @keyup.enter="handleCustomSearch">
                </form>
            </div>
        </div>

        <p v-if="search">Showing results for your query: {{ search }}</p>

        <div class="grid md:grid-cols-3 grid-cols-1 gap-4">
            <list-post
                v-for="(post, index) in posts"
                v-bind:key="index"
                class="w-full object-cover h-full rounded-lg"
                :post="post"
            ></list-post>
        </div>

    </section>

</template>

<style scoped>
</style>