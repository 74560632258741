import { createWebHistory, createRouter } from 'vue-router'

import AppHome from './components/AppHome.vue'
import PostShow from './components/PostShow.vue'

const routes = [
  { path: '/', component: AppHome },
  { path: '/article/:article', component: PostShow, name: 'article.show' }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.query._action === 'totop') {
      return { top: 0 };
    }
    return savedPosition || { top: 0 };
  },
})

export default router
