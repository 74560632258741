<script setup>

    import { defineProps } from 'vue';
    
    defineProps({
        post : Object,
    })
</script>
<script>
    export default {
        name : 'ListPost'
    }
</script>
<template>
    <RouterLink  :to="{ name : 'article.show', params: { article: post.post_name } }">
        <div class="w-full border rounded-lg shadow-sm" data-category="{{ post.category.title }}">
            <div id="post-thumbnail">
                <!-- <img class="rounded-t-lg object-fit" :src="post.thumbnail_url" alt=""> -->
                <img class="rounded-t-lg object-fit" :src="'images.jpeg'" alt="">
            </div>
            <div class="flex flex-col items-start space-y-2 p-2" id="post-info">
                <h2 class="font-bold">{{ post.post_title }}</h2>
                <p>{{ post.post_description }}</p>
                <div class="w-full flex flex-row justify-between items-center">
                    <div class="text-xs" id="post-meta">
                        <span>2024 Jan 2</span>
                    </div>
                    <div class="text-xs" id="read-more">
                        <span>Read more</span>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="loading" class="w-full border rounded-lg shadow-sm">
            <div class="h-14 bg-slate-700 w-full" id="post-thumbnail">

            </div>
        </div>
    </RouterLink>
</template>
<style scoped>
</style>