<script setup>
    import { ref, defineProps, onMounted } from 'vue'

    const colors = ref({
        'bg' : 'dark:bg-slate-700 dark:text-slate-50 bg-slate-50 text-slate-700',
        'nobg' : 'dark:text-slate-700 text-slate-50',
    })

    const props = defineProps({
        posts: Object,
    })

    const activeSlide = ref(0)

    const toggleClass = (index) => {
        activeSlide.value = index
    }

    onMounted(() => {
        if (props.posts && props.posts.length > 0) {
            activeSlide.value = 0
        }
    })
</script>

<script>
    export default {
        name: 'BlogSlider',
    }
</script>

<template>
    <section v-bind:class="colors.bg"
        class="
            rounded-lg
            h-96
        "
    >
        <transition name="fade" mode="out-in">
            <div
                v-if="props.posts && props.posts.length > 0"
                :key="activeSlide"
                class="w-full h-full rounded-lg relative blog-slider mb-4"
            >
                <RouterLink :to="{ name: 'article.show', params: { article: props.posts[activeSlide].post_name } }">
                    <img
                        class="w-full object-cover h-96 rounded-lg"
                        :src="'/images.jpeg'"
                        :alt="props.posts[activeSlide].post_title"
                    >
                    <div class="text-slate-50 font-bold md:text-2xl text-xl absolute right-0 bottom-0 m-4">
                        {{ props.posts[activeSlide].post_title }}
                    </div>
                </RouterLink>
            </div>
        </transition>

        <div
            class="flex flex-row space-x-4 w-full justify-center items-center"
        >
            <button
                v-for="(post, index) in props.posts.slice(0, 5)"
                v-bind:key="index"
                @click="toggleClass(index)"
                class="text-slate-700 text-xs hover:text-slate-400"
                :id="index"
            >
                <i class="fa-solid fa-circle"></i>
            </button>
        </div>
    </section>
</template>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter-from, .fade-leave-to {
    opacity: 0;
}
</style>
