<script setup>
    import { defineProps } from 'vue';

    defineProps({
        post : Object
    })
</script>
<script>
    export default {
        name : 'PostBody'
    }
</script>


<template>
    
    <article class="mb-5 space-y-4">
        <div class="article-thumbnail mb-8">
            <!-- <img :src="'/' + post.thumbnail_url" class="rounded-lg" alt=""> -->
            <img :src="'/images.jpeg'" class="rounded-lg" alt="">
        </div>

        <h1 class="font-bold text-3xl mb-5">{{ post.post_title }}</h1>

        <div class="article-inner font-serif text-lg" v-html="post.post_content"></div>

        <div class="article-metadata font-serif flex flex-row justify-between items-center">
            <div>
                <span v-for="(tag, index) in post.post_tags" v-bind:key="index">{{ tag }}</span>
            </div>
            <div>
                <span>Last Modified: {{ post.updated_at }}</span>
            </div>
        </div>
    </article>

</template>

<style scoped>

</style>